/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
import { rpApi, rpNft } from "@/http";

export default {
  getSMSCode(params: any) {
    return rpApi.post("/signin/action/send-verify-code", params);
  },
  loginWithSmsCode(params: any) {
    return rpApi.post("/signin/login/phone", params);
  },
  getUserInfo(params: any) {
    return rpNft.post("/account/get-profile-page", params);
  },
  goCertification(params: any) {
    return rpNft.post("/account/certification", params);
  },
};
