<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div class="auth">
    <div class="auth-title">
      <h1 class="auth-title-label">填写实名认证信息</h1>
    </div>
    <div class="auth-content">
      <div class="auth-content-phone">当前登录手机号：{{ userInfo.phone }}</div>
      <div class="auth-content-tips">
        按照法律要求，购买作品前需要使用年满 14 周岁的身份完成认证
      </div>
      <div class="name">
        <div>姓名</div>
        <div class="input-wrp">
          <input class="input-wrp-item" type="text" v-model="loginForm.name" />
        </div>
      </div>
      <div class="idnumber">
        <div>身份证号</div>
        <div class="input-wrp">
          <input
            maxlength="18"
            class="input-wrp-item"
            type="text"
            v-model="loginForm.idNo"
          />
        </div>
      </div>
    </div>
    <div class="auth-btns">
      <div class="auth-btns-submit">
        <van-button type="primary" size="large" @click="handleSubmit">
          提交认证
        </van-button>
      </div>
      <div class="auth-btns-back">
        <van-button size="large" plain @click="handleBack">
          暂不认证
        </van-button>
      </div>
    </div>
    <div class="auth-safety">
      <img
        src="https://cdn.qiandaoapp.com/ued/5bc4961961e81be6960f79015c5a61b8.png"
        alt=""
      />
      <div>信息安全保障中，信息仅用于身份确认</div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
  Button as VanButton,
  showFailToast,
  showSuccessToast,
  closeToast,
} from "vant";
import { storageKeys } from "@/constant/storage";
import { REQUEST_OK } from "@/constant/network";
import ApiLogin from "@/apis/login";

const router = useRouter();

const userInfo = ref(JSON.parse(localStorage.getItem(storageKeys.USER_INFO)));
const loginForm = ref({
  name: "",
  idNo: "",
});

const idNumberRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

const handleSubmit = async () => {
  const { name, idNo } = loginForm.value;

  if (!name) {
    showFailToast("请填写姓名");
    return;
  }

  if (!idNo || idNo.length !== 18 || !idNumberRegex.test(idNo)) {
    showFailToast("请填写18位身份证号");
    return;
  }

  const res = await ApiLogin.goCertification({
    name: loginForm.value.name.toString(),
    idNo: loginForm.value.idNo.toString(),
  });
  if (res.code !== REQUEST_OK) {
    showFailToast("认证失败，请检查表单后重新提交");
    return;
  }
  showSuccessToast("认证成功");
  const userInfoRes = await ApiLogin.getUserInfo({});
  if (userInfoRes.code !== REQUEST_OK) {
    showFailToast("获取用户信息失败, 请刷新页面重新登录");
    return;
  }

  const currentUserInfo = JSON.parse(
    localStorage.getItem(storageKeys.USER_INFO) || "{}"
  );
  const resultInfo = {
    ...currentUserInfo,
    ...(userInfoRes?.data?.account || {}),
  };
  closeToast();
  localStorage.setItem(storageKeys.USER_INFO, JSON.stringify(resultInfo));
  setTimeout(() => {
    handleBack();
  }, 500);
};

const handleBack = () => {
  router.back();
};
</script>

<style lang="scss">
.auth {
  //color: var(--test-red);
  &-title {
    position: relative;
    width: 100vw;
    height: 372rpx;
    background: var(--primary-linear-gradient);
    &-label {
      position: absolute;
      left: 64rpx;
      top: 260rpx;
      font-weight: 800;
      font-size: 64rpx;
      line-height: 80rpx;
      color: #ffffff;
    }
  }
  &-content {
    padding: 48rpx 64rpx;
    &-phone {
      font-weight: 800;
      font-size: 32rpx;
      line-height: 48rpx;
      color: #000000;
    }
    &-tips {
      margin-top: 16rpx;
      font-weight: 400;
      font-size: 32rpx;
      line-height: 48rpx;
      color: #000000;
    }
    .name {
      margin-top: 48rpx;
    }
    .idnumber {
      margin-top: 16rpx;
    }

    .input-wrp {
      margin-top: 16rpx;
      //box-sizing:border-box;
      //border-radius:16rpx;
      //
      //box-sizing:border-box;
      //border:1px solid rgba(0, 0, 0, 0.1);
      //border-radius:16rpx;
      &-item {
        transition: all 0.1s;
        //padding-left: 32rpx;
        text-indent: 16rpx;
        width: 622rpx;
        height: 96rpx;
        background: white;
        box-sizing: content-box;
        border: 2rpx solid rgba(0, 0, 0, 0.1);
        border-radius: 16rpx;
        font-family: "Roboto";
        font-weight: 800;
        font-size: 40rpx;
        line-height: 52rpx;
        color: #000000;
      }
      &-item:focus {
        border: 2rpx solid #2b1ac0;
      }
    }
  }
  &-btns {
    margin: 32rpx auto 0 auto;
    width: 622rpx;
    &-submit {
    }
    &-back {
      margin-top: 24rpx;
    }
  }
  &-safety {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25rpx;
    font-weight: 400;
    font-size: 20rpx;
    line-height: 22rpx;
    color: rgba(0, 0, 0, 0.5);
    img {
      margin-right: 8rpx;
      width: 24prx;
      height: 24rpx;
    }
  }
}
:root:root {
  .van-button--primary {
    font-weight: bold;
    --van-button-border-width: 0rpx;
    //--van-button-primary-border-color: yellow;
  }
  .van-button--plain {
    font-weight: bold;
    --van-button-border-width: 2rpx;
    --van-button-default-color: var(--primary-purple);
    --van-button-default-border-color: var(--primary-purple);
    //--van-button-primary-border-color: yellow;
  }
  //--van-button-primary-border-color: red;
  //--van-button-border-width: 0;
}
</style>
